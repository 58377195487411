import React from 'react';
import PropTypes from 'prop-types';

const FormattedText = ({ className, text = '', tag = 'div', id = null, ...props }) => {
  const Tag = `${tag}`;
  return (
    <Tag
      id={id}
      // Styles for component defined in the global _typography.scss styles.
      className={`formatted-text ${className}`}
      dangerouslySetInnerHTML={{ __html: text }}
      {...props}
    />
  );
};

FormattedText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  tag: PropTypes.string,
  id: PropTypes.string,
};

export default FormattedText;
